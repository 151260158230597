import React, { Component } from "react";
import Resume from "../assets/resume.pdf";
import Fade from "react-awesome-reveal";
class About extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const profilepic = "images/" + this.props.data.image;
    const bio = this.props.data.bio;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const email = this.props.data.email;

    return (
      <section id="about">
                <Fade duration={1000}>
                <div className="row">
        <div className="three columns">
            <img
              className="profile-pic"
              src={profilepic}
              alt="Nordic Giant Profile Pic"
            />
            <p>
              <a href={Resume} className="button" download="Resume">
                <i className="fa fa-download"></i>Download Resume
              </a>
            </p>
          </div>
          <div className="nine columns main-col">
              <div className="columns about-me">
                <h2>About Me</h2>
                <p>{bio}</p>
              </div>
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{name}</span>
                  <br />
                  <span>
                    {street}
                    <br />
                    {city} {state}, {zip}
                  </span>
                  <br />
                  <span>{phone}</span>
                  <br />
                  <span>{email}</span>
                </p>
              </div>
            </div>
          </div>
                </Fade>
      </section>
    );
  }
}

export default About;
